import { ReactNode, useEffect, useState } from "react"

import { ClerkLoaded, RedirectToSignIn, SignedIn, SignedOut, UserButton } from "@clerk/nextjs"
import clsx from "clsx"
import CheckIcon from "vector/icons/check_icon.svg"

import { DashboardState } from "helpers/dashboardHelper"
import { useUserPlan } from "helpers/hooks/planHook"
import DashboardLayoutNavigation from "./DashboardLayoutNavigation"
import styles from "./TwoColumnDashboardLayout.module.css"
import Layout from "../../Layout"
// import { UserBlacklistedModal } from "../../../onboarding/components/UserBlacklistedModal"
import FrontChatWidget from "../../../widgets/FrontChatWidget"

type Props = {
  state: DashboardState
  children?: undefined
  left: ReactNode
  right?: {
    content: ReactNode
    title: string
  }
}

export const TwoColumnDashboardLayout = (props: Props) => {
  const [isCypress, setIsCypress] = useState(undefined)

  useEffect(() => {
    setIsCypress(!!window.Cypress)
  }, [])

  if (isCypress === undefined) {
    return <></>
  }

  if (isCypress) {
    return (
      <Layout showHeader={false} showFooter={false}>
        <TwoColumnDashboardLayoutAuthenticated {...props} />
      </Layout>
    )
  }
  return (
    <>
      <SignedIn>
        <Layout showHeader={false} showFooter={false}>
          <TwoColumnDashboardLayoutAuthenticated {...props} />
        </Layout>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  )
}

const TwoColumnDashboardLayoutAuthenticated = ({ left, right, state }: Props) => {
  const verificationNeeded = state?.state === "IRS_REQUIRED"
  const { myPlan } = useUserPlan()

  const title =
    myPlan?.user?.given_name || myPlan?.user?.name
      ? `Hello, ${myPlan?.user?.given_name || myPlan?.user?.name}`
      : "Hello"

  return (
    <div className={styles.container}>
      <FrontChatWidget />
      <DashboardLayoutNavigation verificationNeeded={verificationNeeded} whiteBg={true} />
      {!verificationNeeded && (
        <div className={styles.header}>
          {!["IRS_REQUIRED", "LOADING"].includes(state?.state) && !!state?.state && (
            <div className={styles.title}>
              {title}{" "}
              {state?.onTrack && (
                <div className={styles.onTrackPill}>
                  <CheckIcon />
                  Plan is on track
                </div>
              )}{" "}
            </div>
          )}
          {!verificationNeeded && state?.state !== "LOADING" && (
            <div className={styles.rightTitle}>
              <ClerkLoaded>
                <div className="hideMobile">
                  <UserButton showName={true} />
                </div>
              </ClerkLoaded>
            </div>
          )}
        </div>
      )}
      <div className={clsx(styles.content, verificationNeeded && styles.irsContent)}>
        <div className={styles.leftContainer}>
          <div className={styles.leftContent}>{left}</div>
        </div>
        {right && <div className={styles.rightContainer}>{right.content}</div>}
      </div>
    </div>
  )
}
