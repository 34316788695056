import styles from "./PieChart.module.css"

interface Props {
  width: number
  height: number
  slices: { color: string; percentage: number }[]
}

const PieChart = ({ width, height, slices }: Props) => {
  const gradient = slices
    .map(({ color, percentage }, index) => {
      const start = slices.slice(0, index).reduce((acc, slice) => acc + slice.percentage, 0)
      const end = start + percentage
      return `${color} ${start}% ${end}%`
    })
    .join(", ")

  const pieStyle = {
    width,
    height,
    borderRadius: "50%",
    background: `conic-gradient(${gradient})`,
    border: "1px solid #A7ABB0",
  }

  return <div className={styles.pieChart} style={pieStyle}></div>
}

export default PieChart
