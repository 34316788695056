import { useEffect } from "react"
import type { ReactNode } from "react"
import { useRouter } from "next/router"
import { useUser, UserButton, ClerkLoaded } from "@clerk/nextjs"

import DashboardAdminLayoutNavigation from "./DashboardAdminLayoutNavigation"
import ConfirmationIcon from "../../../../vector/icons/wealth-confirmation.svg"
import WarningIcon from "../../../../vector/icons/warning2.svg"
import ErrorIcon from "../../../../vector/icons/wealth-error.svg"

import styles from "../TwoColumnLayout/TwoColumnLayout.module.css"
import { Space } from "../.."

type Props = {
  title: string | ReactNode
  subheadStatus?: {
    title: string
    status: "success" | "warning" | "error" | "loading"
    icon?: ReactNode
  }
  subhead?: string
  children?: undefined
  left: ReactNode
  right?: {
    content: ReactNode
  }
}

export const TwoColumnAdminLayout = ({ title, subheadStatus, subhead, left, right }: Props) => {
  const { user, isLoaded } = useUser()
  const router = useRouter()

  // it's okay to do this client side because the endpoints
  // have security checks!
  useEffect(() => {
    if (!isLoaded) return

    // not logged in
    if (!user) {
      router.push("/signin")
      return
    }
    // not an admin, send to regular dashboard
    if (user.publicMetadata.user_role !== "admin") router.push("/dashboard")
  }, [user])

  return (
    <div className={styles.container}>
      <DashboardAdminLayoutNavigation />
      <div className={styles.content}>
        <div className={right ? styles.leftContainer : styles.leftContainerSolo}>
          <div className={styles.leftHeaderContainer}>
            <div>
              <h2 className={styles.title}>
                {title}
                {!right && (
                  <ClerkLoaded>
                    <div className="hideMobile">
                      <UserButton />
                    </div>
                  </ClerkLoaded>
                )}
              </h2>
              {subheadStatus && subheadStatus.status !== "loading" && (
                <Space direction="row" className={styles.subheadContainer} gap={null}>
                  <div>{getSubheadIconFromStatus(subheadStatus)}</div>
                  <div>
                    <div className={getSubheadStyleFromStatus(subheadStatus.status)}>{subheadStatus.title}</div>
                    {subhead && <div className={styles.subhead}>{subhead}</div>}
                  </div>
                </Space>
              )}
            </div>
          </div>
          <div className={styles.leftContent}>{left}</div>
        </div>
        {right && (
          <div className={styles.rightContainer}>
            <div className={styles.rightTitle}>
              <ClerkLoaded>
                <div className="hideMobile">
                  <UserButton />
                </div>
              </ClerkLoaded>
            </div>
            {right.content}
          </div>
        )}
      </div>
    </div>
  )
}

function getSubheadStyleFromStatus(status: Props["subheadStatus"]["status"]) {
  switch (status) {
    case "success":
      return `${styles.subheadStatus} ${styles.subheadStatusSuccess}`
    case "warning":
      return `${styles.subheadStatus} ${styles.subheadStatusWarning}`
    case "error":
      return `${styles.subheadStatus} ${styles.subheadStatusError}`
    case "loading":
      return `${styles.subheadStatus} ${styles.subheadStatusLoading}`
    default:
      return styles.subheadStatus
  }
}

function getSubheadIconFromStatus(subheadStatus: Props["subheadStatus"]) {
  if (subheadStatus.icon) {
    return subheadStatus.icon
  }
  switch (subheadStatus.status) {
    case "success":
      return <ConfirmationIcon className={styles.subheadIcon} />
    case "warning":
      return <WarningIcon className={styles.subheadIcon} />
    case "error":
      return <ErrorIcon className={styles.subheadIcon} />
    default:
      return null
  }
}
